import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeroRefer from "../components/Rebrand/Hero/HeroRefer";
import Seo from "./../components/Seo";
import { Section, Button } from "./../components/Core";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from 'styled-components';
import {
  PiCaretCircleLeftLight,
  PiCaretCircleRightLight,
} from 'react-icons/pi';

import icoClick from "../assets/image/rebrand/svg/icon-click.svg";
import icoLink from "../assets/image/rebrand/svg/icon-link.svg";
import icoWiFi from "../assets/image/rebrand/svg/icon-wifi.svg";
import icoMoney from "../assets/image/rebrand/svg/icon-money.svg";
import doubleMoney from "../assets/image/rebrand/svg/double-money.svg";
import money from "../assets/image/rebrand/svg/money.svg";

const Package = styled.div`
  position: relative;
  margin: 50px auto 0px auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 1px solid #09141e;
  max-width: 300px;
  .package-head {
    flex: 1;
    background-color: #09de9e;
    h3, span { padding: 0px !important; }
    h3 { color: #09141e !important; }
    span { color: #09141e; }
    padding: .5rem .5rem 1.2rem .5rem;
    border-radius: 1rem 1rem 0px 0px;
  }
  .package-body {
    flex: 1;
    padding: .5rem;
    h4, span { padding: 0px !important; }
  }
  .double-money {
    position: absolute;
    z-index: 2;
    top: -50px;
    right: 0px;
    width: 80px;
    height: 80px;
  }
  .old-price {
    display: block;
    position: absolute;
    left: 10%;
    font-weight: 900;
    font-size: 1.5rem !important;
    &:before {
      position: absolute;
      content: "";
      height: 40px;
      width: 56px;
      text-align: center;
      margin-top: 12px;
      border-top: 2px solid red;
      -webkit-transform: rotate(340deg);
      -moz-transform: rotate(340deg);
      -ms-transform: rotate(340deg);
      -o-transform: rotate(340deg);
      -webkit-transform: rotate(340deg);
      -ms-transform: rotate(340deg);
      transform: rotate(340deg);
    }
  }
  @media (max-width: 576px) {
    .old-price {
      left: 5%;
    }
  }
`;

const Icon = styled.img`
  width: 100px;
`;

const Small = styled.div`
  font-size: 0.8rem;
  position: relative;
  margin: 20px auto;
  max-width: 600px !important;
  text-align: center;
`;

const Price = styled.div`
  font-family: DM Sans;
  font-size: 3.5rem;
  line-height: 3.5rem !important;
  font-weight: 900;
  color: #07131E;
`;

const Refer = () => {

  const responsive1 = {
      0: { items: 1 },
      568: { items: 1 },
      768: { items: 3 },
      1024: { items: 4 },
  };

  const responsive2 = {
      0: { items: 1 },
      568: { items: 1 },
      768: { items: 2 },
      1024: { items: 3 },
      1200: { items: 3 },
  };

  return (
    <>
      <Seo page="refer" />
      <HeroRefer />      
      <Section className="text-center pt-5 pb-5">
        <Container>
          <Row>
            <Col md={12} className="pb-5">
              <h2>HOW IT WORKS</h2>
            </Col>
            <Col xs={12} className="company-values center-items ph-40 hide-arrows-1200">
              <AliceCarousel
                disableDotsControls
                responsive={responsive1}
                infinite={false}
                keyboardNavigation={true}
                renderPrevButton={() => {
                  return <PiCaretCircleLeftLight />
                }}
                renderNextButton={() => {
                  return <PiCaretCircleRightLight />
                }}
                controlsStrategy="alternate">
                <div className="center-items pb-3">
                  <img src={icoClick} className="mb-2 mw-100" />
                  <p className="px-3">Get started – register at <a href="https://youfibre.aklamio.com/?source=website_refer_cta"><u>youfibre.aklamio.com</u></a></p>
                </div>
                <div className="center-items pb-3">
                  <img src={icoLink} className="mb-2 mw-100" />
                  <p className="px-3">You’ll get a unique referral link & referral account</p>
                </div>
                <div className="center-items pb-3">
                  <img src={icoWiFi} className="mb-2 mw-100" />
                  <p className="px-3">Bang on about our broadband and share your link</p>
                </div>
                <div className="center-items pb-3">
                  <img src={icoMoney} className="mb-2 mw-100" />
                  <p className="px-3">We’ll boost both your bank accounts when they join us using your link</p>
                </div>
              </AliceCarousel>
            </Col>
          </Row>
        </Container>
      </Section>  
      <Section className="text-center pt-1 pb-5">
        <Container>
          <Row>
            <Col md={12} className="pb-5">
              <h2>PACKAGES THAT PAY</h2>
              <p>The more speed they need, the bigger the boost for both of you.</p>
            </Col>
            <Col xs={12} className="company-values center-items ph-40 ">
            <AliceCarousel
                disableDotsControls
                responsive={responsive2}
                infinite={false}
                keyboardNavigation={true}
                renderPrevButton={() => {
                  return <PiCaretCircleLeftLight />
                }}
                renderNextButton={() => {
                  return <PiCaretCircleRightLight />
                }}
                controlsStrategy="alternate">
                  <Package>
                    <div className="package-head">
                      <h3>You 150</h3>
                      <span>150Mbps broadband</span>
                    </div>
                    <div className="package-body">
                      <span>You both get</span>
                      <Price>£25</Price>
                      <span><b>Bank Boost</b></span>
                    </div>
                  </Package>
                  <Package>
                    <div className="package-head">
                      <h3>You 500</h3>
                      <span>500Mbps broadband</span>
                    </div>
                    <div className="package-body">
                      <span>You both get</span>
                      <Price>£50</Price>
                      <span><b>Bank Boost</b></span>
                    </div>
                  </Package>
                  <Package>
                    <div className="package-head">
                      <h3>You 1000</h3>
                      <span>1000Mbps broadband</span>
                    </div>
                    <div className="package-body">
                      <span>You both get</span>
                      <Price>£75</Price>
                      <span><b>Bank Boost</b></span>
                    </div>
                  </Package>
                  <Package>
                    <div className="package-head">
                      <h3>You 2000</h3>
                      <span>2000Mbps broadband</span>
                    </div>
                    <div className="package-body">
                      <span>You both get</span>
                      <Price>£100</Price>
                      <span><b>Bank Boost</b></span>
                    </div>
                  </Package>
                  <Package>
                    <div className="package-head">
                      <h3>You 8000</h3>
                      <span>8000Mbps broadband</span>
                    </div>
                    <div className="package-body">
                      <span>You both get</span>
                      <Price>£100</Price>
                      <span><b>Bank Boost</b></span>
                    </div>
                  </Package>
              </AliceCarousel>
            </Col>
          </Row>
          <Row className="mt-50">
            <Col>
              <Button onClick={() => window.location.href = 'https://youfibre.aklamio.com/?source=website_refer_cta'} role="link" className="medium-button blue-bg mt-2 mb-4">
                Refer&nbsp;now
              </Button>
              <Small>Terms and conditions:</Small>
              <Small>
                Current reward amount is valid for successful referrals made between 25.9.24 to 30.11.24 only.
                Anyone who’s anyone can bang on about our broadband and earn themselves a boost. And there’s no limit on
                how many boosts you earn, either. To receive the boosts to both your bank accounts your pal must be
                live - that’s installed and getting our brilliant broadband service. They’ll need to have
                paid their first bill, too. When your friends order they must use your unique referral link
                to get to <u onClick={() => window.location.href = 'https://youfibre.aklamio.com/?source=website_refer_cta'}>youfibre.com</u>
              </Small>
            </Col>
          </Row>
        </Container>
      </Section>
      <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
    </>
  );
};
export default Refer;

